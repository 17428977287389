<template>
	<div
		v-if="active"
		class="cookie-banner"
		data-animation="fadeUp"
	>
		<p>
			This website is using <a
				href="https://policies.google.com/privacy"
				title="More on Google Analytics"
				rel="noopener noreferrer"
				target="_blank"
			>Google Analytics</a>.
		</p>
		<div class="buttons">
			<button
				type="button"
				class="reject"
				@click="_onReject"
			>
				No, thanks
			</button>
			<button
				class="accept"
				type="button"
				@click="_onAccept"
			>
				It's okay
			</button>
		</div>
	</div>
</template>

<script>
import {useState} from 'vue-gtag-next';
import {clearCookies} from '@schascha/brabbelback';

export default {
	data() {
		return {
			active: false
		};
	},
	mounted() {
		const
			value = window.localStorage.getItem('cookies')
		;

		if (value === null) {
			this.active = true;
		}
	},
	methods: {
		_update(value) {
			const
				{isEnabled} = useState()
			;

			isEnabled.value = value;
			window.localStorage.setItem('cookies', value);
			this.active = false;
		},
		_onAccept() {
			this._update(true);
		},
		_onReject() {
			this._update(false);
			clearCookies();
		},
	}
};
</script>

<style lang="scss" scoped>
	.cookie-banner {
		position: fixed;
		bottom: 1em;
		left: 1em;
		z-index: 100;
		padding: 1rem;
		width: 320px;
		max-width: calc(100% - 2rem);
		background-color: var(--color-content);
		border-radius: 1rem;
		box-shadow: 0 6px 30px rgb(0 0 0 / 30%);
		font-size: 1rem;
	}

	button {
		padding: 0;
	}

	.buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.accept {
		padding: 0.5rem 1rem;
		background-color: var(--color-success);
		border-radius: 3px;
		color: #fff;
		font-weight: 600;
	}
</style>
